<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(bt400lg) {{ content || $t('codeInsertV2.wordpress.description') }}
  om-accordion.mt-6
    om-accordion-item(
      :step="1"
      :trackPrefix="getSetupGuideTrackPrefix('step1')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.wordpress.steps.0.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.wordpress.steps.0.texts.0')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/wordpress/step_1_1.png')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.wordpress.steps.0.texts.1') }}
        img.my-3(:src="require('@/assets/admin/img/insert-code/platforms/wordpress/step_1_2.png')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.wordpress.steps.0.texts.2') }}

    om-accordion-item(
      :step="2"
      :trackPrefix="getSetupGuideTrackPrefix('step2')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.wordpress.steps.1.title', { userId }) }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.wordpress.steps.1.texts.0', { userId })")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.wordpress.steps.1.texts.1') }}
        img.mt-5(:src="require('@/assets/admin/img/insert-code/platforms/wordpress/step_2_1.png')")
        om-body-text.mt-5.mb-3(bt400md) {{ $t('codeInsertV2.wordpress.steps.1.texts.2') }}

    om-accordion-item(
      :step="3"
      :trackPrefix="getSetupGuideTrackPrefix('step3')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.wordpress.steps.2.title') }}
      om-body-text(bt400md) {{ $t('codeInsertV2.wordpress.steps.2.texts.0') }}
      om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.wordpress.steps.2.texts.1') }}
      ul.mt-5
        li {{ $t('codeInsertV2.wordpress.steps.2.texts.2') }}
        li {{ $t('codeInsertV2.wordpress.steps.2.texts.3') }}
      om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.wordpress.steps.2.texts.4') }}
      om-body-text.mt-5.mb-3(bt400md v-html="$t('codeInsertV2.wordpress.steps.2.texts.5')")
</template>

<script>
  import { getAccountIdFromCookie } from '@/util';
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    name: 'Wordpress',
    mixins: [childRouteMixin, domainStatusMixin, platformMixin],
    computed: {
      userId() {
        return getAccountIdFromCookie();
      },
    },
  };
</script>
